<template>
    <div class="home">
        <div class="banner">
          <div class="banner-content">
              <div class="banner-content-left">
                <swiper :options="swiperOption">
                  <swiper-slide v-for="(slide, index) in swiperSlides" :key="index" class="swiper-wrap">                    
                      <img :src="slide" style="display:block;height:100%;width:100%;" />
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
              </div>
              <div class="banner-content-right">
                  <div class="login"  v-show="!login">
                <div class="container">
                    <div class="login-code">
                        <h6>帐号登录</h6>
                        <Form ref="formInline" :model="formInline" :rules="ruleInline" inline>
                            <!-- 填写信息 -->
                            <FormItem prop="user">
                                <Input type="text" v-model="formInline.user" placeholder="用户名/手机号">
                                    <Icon type="ios-person-outline" slot="prepend"></Icon>
                                </Input>
                            </FormItem>
                            <FormItem prop="password">
                                <Input type="password" v-model="formInline.password" placeholder="密码">
                                    <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                </Input>
                            </FormItem>
                            <!-- 是否忘记密码 -->
                            <FormItem>
                                <div class="forget">
                                    <div class="log-in">
                                        <input type="checkbox">
                                    <div>自动登录</div>
                                    </div>
                                    <div class="forget-beg">
                                       <router-link :to="{name:'forget'}">忘记密码？</router-link>
                                    </div>
                                </div>
                            </FormItem>
                            <FormItem>
                                <div class="login-btn">
                                    <Button style="" @click="handleSubmit('formInline')">登录</Button>
                                </div>
                            </FormItem>
                        </Form>
                        <div class="footer-login">
                            没有帐号？<a @click="zhuce">立即注册</a>
                        </div>
                    </div>
                </div>
            </div>
              </div>
          </div>
        </div>
        <div class="image-display">
            <div class="image-display-title">
                <h4>精品游戏</h4>
            </div>
            <ul>
                <li  v-for="(item, i) in lists" :key="i" @click="down(i)">
                    <img class="card-img-top" v-lazy="item.img" alt="Card image cap">
                    <div class="card-img-top-name">
                       <div class="card-img-top-name-bg">

                       </div>
                       <div class="card-img-top-name-color">
                          {{item.title}}
                       </div>
                    </div>
                    <div class="specific">
                        <div class="specific-bg">

                       </div>
                       <div class="specific-color">
                         <div class="specific-color-name">
                           <span>{{item.title}}</span>
                           <span>角色扮演</span>
                         </div>
                         <div class="specific-color-where">
                         </div>
                       </div>
                    </div>
                </li>
            </ul>
        <FooterInfo></FooterInfo>
        </div>
        <div v-if="zhucezhuce" class="zhuce" >
    </div>
    <div v-if="zhucezhuce"  class="zhucekuan">
        <div class="zhuce_news">
        <h1>快速注册</h1>
        <div id="contant" class="zhuce-title">
          <p  class="zhuce-title-1 active" @click="zhanghao(0)">帐号注册</p>
          <p  class="zhuce-title-1" @click="zhanghao(1)">手机注册</p>
        </div>
        <div v-if="content==0" class="zhuce-leirong">
          <div>
            <div class="news">
              <p>帐 &nbsp;&nbsp;&nbsp;号：</p>
              <Input v-model="zhangzhang" placeholder="请输入帐号" style="width: 200px" />
            </div>
            <div class="news">
              <p>密 &nbsp;&nbsp;&nbsp;码：</p>
              <Input v-model="zhangmima" type="password" placeholder="请输入密码" style="width: 200px" />
            </div>
            <div class="news">
              <p>姓 &nbsp;&nbsp;&nbsp;名：</p>
              <Input v-model="zhangxingm"  placeholder="请输入姓名" style="width: 200px" />
            </div>
            <div class="news">
              <p>身份证：</p>
              <Input v-model="zhangsfz"  placeholder="请输入身份证号" style="width: 200px" />
            </div>
            <div class="news" style="width:200px;color:red;">
              <p style="width:100%;text-align:center;">{{warn}}</p>
            </div>
          </div>
          <div  class="submit">
            <p @click="quxiao">取消</p>
            <p @click="kszc">快速注册</p>
          </div>
        </div>
        <div v-if="content==1" class="zhuce-leirong">
           <div>
            <div class="news">
              <p>手机号：</p>
              <Input v-model="zhangzhang" placeholder="请输入帐号" style="width: 200px" />
            </div>
             <div class="news">
              <p>验证码：</p>
              <Input v-model="yzm" placeholder="请输入验证码" style="width: 100px" />
              <Button type="primary" @click="fama" v-bind:disabled="wwww" style="padding:0 5px;width:80px;margin-left:10px;">{{countdown}}</Button>
            </div>
            <div class="news">
              <p>密 &nbsp;&nbsp;&nbsp;码：</p>
              <Input v-model="zhangmima" type="password" placeholder="请输入密码" style="width: 200px" />
            </div>
            <div class="news">
              <p>姓 &nbsp;&nbsp;&nbsp;名：</p>
              <Input v-model="zhangxingm" placeholder="请输入姓名" style="width: 200px" />
            </div>
            <div class="news">
              <p>身份证：</p>
              <Input v-model="zhangsfz" placeholder="请输入身份证" style="width: 200px" />
            </div>
             <div class="news" style="width:200px;color:red;">
              <p style="width:100%;text-align:center;">{{warn}}</p>
            </div>
          </div>
          <div class="submit">
            <p @click="quxiao">取消</p>
            <p @click="kszc">快速注册</p>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>


<script>
import axios from "axios";
import base from "../api/base.js";
import FooterInfo from "../components/footer";
import { mapState, mapMutations, mapActions } from "vuex";
// 引入图片
import banner1 from "../assets/images/banner1.jpg";
import banner2 from "../assets/images/banner2.jpg";
import jinp1 from "../assets/images/new_change(1).jpg";
import jinp2 from "../assets/images/new_change(2).jpg";
import jinp3 from "../assets/images/jinp (3).jpg";
import jinp4 from "../assets/images/jinp (4).jpg";
export default {
  name: "home",
  computed: {
    ...mapState(["login"]),
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  components: {
    FooterInfo
  },
  data() {
    return {
      // 账号注册
      wwww: false,
      countdown: "请发送验证码",
      warn: "",
      zhangzhang: "",
      zhangmima: "",
      zhangxingm: "",
      zhangsfz: "",
      yzm: "",
      value: "",
      content: 0,
      zhucezhuce: false,
      formInline: {
        user: "",
        password: ""
      },
      mounted() {
        this.swiper.slideTo(1, 1000, false);
      },
      ruleInline: {
        user: [{ required: true, message: "请输入帐号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      formInline2: {
        user: "",
        password: "",
        sjh: "",
        sfz: ""
      },
      ruleInline2: {
        user: [{ required: true, message: "请输入帐号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        sjh: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
        sfz: [{ required: true, message: "请输入身份证号码", trigger: "blur" }]
      },
      swiperOption: {
        pagination: {
          el: ".swiper-pagination"
        }
      },
      swiperSlides: [banner1, banner2],
      lists: [
        {
          title: "仙剑奇侠传",
          img: jinp2,
          mark: "快来开心一下"
        },
        {
          title: "三国志",
          img: jinp1,
          mark: "快来开心一下"
        }
      ]
    };
  },
  methods: {
    fama() {
      this.wwww = true;
      if (this.zhangzhang == "") {
        this.warn = "账号为空";
        this.wwww = false;
        return;
      } else {
        var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (!myreg.test(this.zhangzhang)) {
          this.warn = "请输入正确的电话号码";
          this.wwww = false;
          return;
        } else {
          let data = {
            phone: this.zhangzhang
          };
          axios.get(base.api + "sendMess", { params: data }).then(res => {
            this.countdown = 60;
            this.jishi();
          });
        }
      }
    },
    jishi() {
      var auth_timetimer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          this.wwww = false;
          this.countdown = "发送验证码";
          clearInterval(auth_timetimer);
        }
      }, 1000);
    },
    quxiao() {
      this.content = 0;
      this.zhangzhang = "";
      this.zhangmima = "";
      this.zhangxingm = "";
      this.zhangsfz = "";
      this.yzm = "";
      this.zhucezhuce = false;
    },
    kszc() {
      if (this.content == 0) {
        if (this.zhangzhang == "") {
          this.warn = "账号为空";
          return;
        }
        if (this.zhangmima == "") {
          this.warn = "密码为空";
          return;
        }
        if (this.zhangxingm == "") {
          this.warn = "姓名为空";
          return;
        } else {
          var str = this.zhangxingm;
          reg = /^([\u4e00-\u9fa5]){2,7}$/; //只能是中文，长度为2-7位
          if (!reg.test(str)) {
            this.warn = "姓名的字符串类型格式不正确!";
            return false;
          }
        }
        if (this.zhangsfz == "") {
          this.warn = "身份证为空";
          return;
        } else {
          var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
          if (reg.test(this.zhangsfz) === false) {
            this.warn = "身份证格式不准确";
            return false;
          } else {
            let data = {
              uAccounts: this.zhangzhang,
              uPass: this.zhangmima,
              uName: this.zhangxingm,
              identityID: this.zhangsfz,
              type: 1
            };
            axios.get(base.api + "register", { params: data }).then(res => {
              if (res.data.code == "200") {
                this.warn = "";
                this.zhucezhuce = false;
                this.zhangzhang = "";
                this.zhangmima = "";
                this.zhangxingm = "";
                this.zhangsfz = "";
                this.yzm = "";
                this.set_login(this.zhangzhang);
              } else {
                this.zhucezhuce = true;
                this.warn = res.data.msg;
              }
            });
          }
        }
      }
      if (this.content == 1) {
        if (this.zhangzhang == "") {
          this.warn = "账号为空";
          return;
        } else {
          var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
          if (!myreg.test(this.zhangzhang)) {
            this.warn = "请输入正确的电话号码";
            return;
          } else {
            if (this.yzm == "") {
              this.warn = "请输入验证码";
              return;
            }
          }
        }
        if (this.zhangmima == "") {
          this.warn = "密码为空";
          return;
        }
        if (this.zhangxingm == "") {
          this.warn = "姓名为空";
          return;
        } else {
          var str = this.zhangxingm;
          reg = /^([\u4e00-\u9fa5]){2,7}$/; //只能是中文，长度为2-7位
          if (!reg.test(str)) {
            this.warn = "姓名的字符串类型格式不正确!";
            return false;
          }
        }

        if (this.zhangsfz == "") {
          this.warn = "身份证为空";
          return;
        } else {
          var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
          if (reg.test(this.zhangsfz) === false) {
            this.warn = "身份证格式不准确";
            return false;
          } else {
            let data = {
              uAccounts: this.zhangzhang,
              uPass: this.zhangmima,
              uName: this.zhangxingm,
              identityID: this.zhangsfz,
              type: 2,
              sms: this.yzm
            };
            axios.get(base.api + "register", { params: data }).then(res => {
              if (res.data.code == "200") {
                this.warn = "";
                this.zhucezhuce = false;
                this.zhangzhang = "";
                this.zhangmima = "";
                this.zhangxingm = "";
                this.zhangsfz = "";
                this.yzm = "";
                this.set_login(this.zhangzhang);
              } else {
                this.zhucezhuce = true;
                this.warn = res.data.msg;
              }
            });
          }
        }
      }
    },
    zhanghao(index) {
      var contant = document.getElementsByClassName("zhuce-title-1");
      contant[0].classList.remove("active");
      contant[1].classList.remove("active");
      contant[index].classList.add("active");
      this.content = index;
      this.warn = "";
      this.zhangzhang = "";
      this.zhangmima = "";
      this.zhangxingm = "";
      this.zhangsfz = "";
      this.yzm = "";
    },
    zhuce() {
      this.content = 0;
      this.zhucezhuce = true;
    },
    down(data) {
      if (data == 0) {
        this.$router.push("/godownload");
      }
      if (data == 1) {
        this.$router.push("/sgzdownload");
      }
    },
    ...mapMutations(["set_login", "set_authStatus", "set_info"]),
    ...mapActions(["get_info"]),
    handleSubmit(name) {
      let that = this;
      this.$refs[name].validate(valid => {
        if (valid) {
          let data = {
            uAccounts: that.formInline.user,
            uPass: that.formInline.password
          };
          axios.get(base.api + "login", { params: data }).then(res => {
            if (res.data.code == "200") {
              this.$Message.info("登录成功!");
              this.set_login(that.formInline.user);
              this.set_authStatus(res.data.authStatus);
              this.set_info(res.data.user);
              this.get_info();
            } else {
              this.$Message.error(res.data.msg ? res.data.msg : "登录失败!");
            }
          });
        } else {
          this.$Message.error("验证失败!");
        }
      });
    },
    goGame() {
      window.open("https://www.yingxiongbb.cn/detail/detail.html", "_blank");
    }
  }
};
</script>

<style>
.home {
  width: 100%;
  height: 100%;
  position: relative;
}
.home .banner {
  position: relative;
  height: 410px;
  background: url("../assets/images/home-bg.jpg") 100% 100%;
}
.banner-content {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.banner-content-left {
  float: left;
  width: 900px;
  height: 400px;
  margin-top: 5px;
}
.banner-content-right {
  float: right;
  width: 276px;
  height: 100%;
}
.login {
  margin-top: 5px;
  width: 100%;
  height: 400px;
  background: #fff;
}
.login .container {
  margin: 0 auto;
  padding: 0;
  position: relative;
}
.login-code {
  position: absolute;
  top: 30px;
  right: 0;
  width: 276px;
  box-sizing: border-box;
  padding: 20px 10px;
  height: 350px;
  border-radius: 5px;
}
.login-code .ivu-form-item {
  width: 100% !important;
}
.login-code h6 {
  background: #f41a3c;
  color: #fff;
  padding: 8px;
  text-align: center;
  margin-bottom: 16px;
}
.login .ivu-form {
  margin-top: 30px;
}
.login-code.ivu-form-item-content {
  width: 256px !important;
  height: 35px !important;
  background: #eef3f8;
}

.login-code .ivu-form-item {
  margin-bottom: 18px;
}
.login-code .forget {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.login-code .forget .log-in {
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.login-code .forget .log-in input {
  margin-top: 10px;
}
.login-code .log-in div {
  padding-left: 5px;
}
.login-code .forget .forget-beg a {
  color: #2d8bf0 !important;
}

.login-btn button {
  color: #fff;
  font-size: 16px;
  background-color: #3db0dd;
  border-color: #fff;
  width: 100%;
  border-radius: 0;
}
.login-btn .ivu-btn {
  width: 256px !important;
}
.footer-login {
  text-align: center;
}
.footer-login a {
  color: #2d8bf0 !important;
}
.image-display {
  width: 100%;
}
.image-display ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 1200px;
  height: 370px;
  margin: 0 auto;
}
.image-display ul li {
  position: relative;
  display: block;
  width: 560px;
  height: 100%;
}
.image-display ul li:last-child {
  margin-left: 20px;
}
.image-display ul li:hover .specific {
  height: 70px;
}
.image-display ul li:hover .card-img-top-name {
  height: 0px;
  overflow: hidden;
}
.image-display ul li .specific {
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 0px;
  transition: height 0.5s;
  -moz-transition: height 0.5s; /* Firefox 4 */
  -webkit-transition: height 0.5s; /* Safari and Chrome */
  -o-transition: height 0.5s; /* Opera */
}
.image-display ul li .card-img-top-name {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  font-size: 16px;
  color: #fff;
}
.image-display ul li .card-img-top-name-bg {
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.8;
  background: #333;
}
.image-display ul li .specific .specific-bg {
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.8;
  background: #e92e3c;
}
.image-display ul li .specific .specific-color {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  padding: 5px 10px;
  font-size: 20px;
  width: 100%;
  color: #fff;
}
.image-display ul li .specific .specific-color .specific-color-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 30px;
  width: 100%;
  height: 30px;
}
.image-display ul li .specific .specific-color .specific-color-where {
  position: relative;
  line-height: 20px;
  width: 100%;
  height: 30px;
}

.image-display ul li .specific .specific-color .specific-color-where .goin {
  position: absolute;
  height: 30px;
  font-size: 10px;
  width: 66px;
  height: 20px;
  display: inline-block;
  background: #f6193c;
  text-align: center;
  line-height: 20px;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  right: 0;
}
.image-display
  ul
  li
  .specific
  .specific-color
  .specific-color-where
  .goin:hover {
  color: #f6193c;
  background: #fff;
  border: 1px solid #f6193c;
}
.image-display
  ul
  li
  .specific
  .specific-color
  .specific-color-name
  span:first-child {
  display: block;
  font-size: 14px;
  font-weight: bold;
}
.image-display
  ul
  li
  .specific
  .specific-color
  .specific-color-name
  span:last-child {
  display: block;
  font-size: 10px;
  color: #ffd8dd;
}
.image-display ul li .card-img-top-name-color {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  line-height: 50px;
}
.card-img-top {
  width: 560px;
  height: 100%;
}
.image-display-title {
  width: 1200px;
  margin: 0 auto;
  padding: 24px 0;
}
.image-display-title h4 {
  padding-left: 20px;
  color: red;
  border-left: 4px solid red;
}
</style>
